import React from 'react';
import styled from 'styled-components';

const AccessibleImageStyles = styled.section`
  img {
    display: block;
    max-height: 60vh;
    object-fit: cover;
    width: calc(100vw - 6px); // accommodate scrollbar
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: initial;
  }
`;

export default function AccessibleImage({ image }) {
  return (
    <AccessibleImageStyles>
      <img src={image.image.asset.url} alt={image.alt} />
    </AccessibleImageStyles>
  );
}
