import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';

const TextSectionStyles = styled.section`
  max-width: 800px;

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2rem 0 1rem;
  }

  .button {
    text-decoration: none;
    &--light {
      @media (min-width: 450px) {
        margin-left: 1rem;
      }
    }
  }

  .content {
    margin-top: 1rem;

    blockquote {
      padding-left: 1rem;
      border-left: 6px solid var(--violet);
      color: var(--violet);

      &:nth-of-type(3n + 2) {
        border-left-color: var(--green);
        color: var(--green);
      }

      &:nth-of-type(3n + 3) {
        border-left-color: var(--blue);
        color: var(--blue);
      }
    }

    @media (min-width: 768px) {
      margin-top: 2rem;

      img {
        max-width: 50%;
      }
    }
  }
`;

const serializers = {
  displayName: 'BlockContent Serializer',
  types: {
    accessibleImage: (props) => (
      <img src={props.node.image.asset.url} alt={props.node.alt} />
    ),
  },
};

export default function TextSection({ content }) {
  const { name, _rawContent, link, link2 } = content || {};

  return (
    <TextSectionStyles>
      <h2>{name}</h2>
      {_rawContent && (
        <div className="content">
          <BlockContent blocks={_rawContent} serializers={serializers} />
        </div>
      )}
      {link && link.url && (
        <Link className="button" to={link.url}>
          {link.link}
        </Link>
      )}
      {link2 && link2.url && (
        <Link className="button button--light" to={link2.url}>
          {link2.link}
        </Link>
      )}
    </TextSectionStyles>
  );
}
